<template>
    <b-container fluid>
                  <b-row>
                     <b-col sm="12">
                        <iq-card>
                           <template v-slot:headerTitle>
                                 <h4 class="card-title">Edit Books</h4>
                           </template>
                           <template v-slot:body>
                              <form @submit.prevent="simpanBuku">
                                 <div class="form-group">
                                    <label>Judul Buku:</label>
                                    <input v-model="dataBuku.judul_buku" type="text" class="form-control">
                                 </div>
                                 <div class="form-group">
                                    <label>Penulis:</label>
                                    <input v-model="dataBuku.penulis" type="text" class="form-control">
                                 </div>
                                 <div class="form-group">
                                    <label>Tahun:</label>
                                    <input v-model="dataBuku.tahun" type="number" class="form-control">
                                 </div>
                                 <div class="form-group">
                                    <label>Kata Kunci:</label>
                                    <b-form-tags input-id="tags-basic" placeholder="kata kunci" v-model="dataBuku.kata_kunci"></b-form-tags>
                                 </div>
                                 <div class="form-group">
                                    <label>Gambar Cover:</label>
                                    <div class="custom-file">
                                       <b-form-file
                                          v-model="dataBuku.coverGambar"
                                          placeholder="Choose a file or drop it here..."
                                          drop-placeholder="Drop file here..."
                                          accept=".jpg, .png, .gif"
                                       ></b-form-file>
                                    </div>
                                 </div>
                                 <div v-if="dataBuku.nama_file_cover != null" class="form-group">
                                    <img class="img-fluid rounded" :src="dataBuku.nama_file_cover" style="max-width: 100px" alt="">
                                 </div>
                                 <div class="form-group">
                                    <label>Buku PDF:</label>
                                    <div class="custom-file">
                                       <b-form-file
                                          v-model="dataBuku.filePDF"
                                          placeholder="Choose a file or drop it here..."
                                          drop-placeholder="Drop file here..."
                                          accept=".pdf"
                                       ></b-form-file>
                                    </div>
                                 </div>
                                 <div class="form-group">
                                    <a :href="dataBuku.file_buku" target="_blank"><i class="fa fa-download" aria-hidden="true"/></a>
                                 </div>
                                 <div class="form-group">
                                    <label>Sinopsis:</label>
                                    <textarea v-model="dataBuku.sinopsis" class="form-control" rows="3"></textarea>
                                 </div>
                                 <!-- <router-link to="/admin/books"> -->
                                 <button @click="simpanBuku()" type="submit" class="btn btn-primary">Submit</button>
                                 <!-- </router-link> -->
                                 <button type="reset" class="btn btn-danger">Reset</button>
                              </form>
                           </template>
                        </iq-card>
                     </b-col>
                  </b-row>
    </b-container>
   </template>
   <script>
   import { core } from '../../config/pluginInit'
   import { mapGetters } from 'vuex'
   import axios from 'axios'
   export default {
     name: 'addbook',
     mounted () {
       core.index()
       this.loadData()
     },
     computed: {
       ...mapGetters({
         lang: 'Setting/langState'
       })
     },
     watch: {
     },
     methods: {
      loadData (){
        axios.get(this.baseapi + "buku/" + this.$route.params.id, {
          headers: {
              // Authorization: `Bearer `+this.user.accessToken
          }
        }).then(response => {
            this.dataBuku = response.data.data;
            this.dataBuku['coverGambar'] = this.dataBuku.nama_file_cover
            this.dataBuku['filePDF'] = this.dataBuku.file_buku
        }).catch(error => {
            console.log(error)
            return error
            });
      },
      simpanBuku () {
         let formData = new FormData();
                formData.append('judul_buku', this.dataBuku.judul_buku);
                formData.append('tahun', this.dataBuku.tahun);
                formData.append('penulis', this.dataBuku.penulis);
                formData.append('sinopsis', this.dataBuku.sinopsis);
                formData.append('kata_kunci', this.dataBuku.kata_kunci);
                formData.append('file_buku', this.dataBuku.filePDF);
                formData.append('file_cover', this.dataBuku.coverGambar);
             axios
               .post(this.baseapi + 'buku/' + this.$route.params.id, formData, {
                headers: {
                    // Authorization: `Bearer ` + this.user.accessToken
                    }
                }
            )
            .then((res) => {
               this.$router.push("/admin/books");
               return res;
            })
            .catch((err) => {
            console.log(err)
            return err;
         });
      }
     },
     data () {
       return {
         dataBuku: [],
         baseapi: localStorage.getItem("baseapi")
       }
     }
   }
   </script>